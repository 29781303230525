import z from 'zod';

export const ImageResponse = z.object({
  urls: z.string().array(),
  price: z.number(),
});
export type ImageResponse = z.infer<typeof ImageResponse>;

export const ImageProvider = z.enum(['flux']);
export type ImageProvider = z.infer<typeof ImageProvider>;

export const FluxModels = [
  'flux-schnell',
  'flux-pro',
  'flux-1.1-pro-ultra',
] as const;

export const IMAGE_MODELS = [...FluxModels] as const;

export const ImageModel = z.enum(IMAGE_MODELS);
export type ImageModel = z.infer<typeof ImageModel>;

export const IMAGE_PROVIDERS_MODELS: Record<ImageProvider, ImageModel[]> = {
  flux: [...FluxModels],
};

export const ImageAspectRatio = z.enum(['1:1', '16:9', '9:16']);
export type ImageAspectRatio = z.infer<typeof ImageAspectRatio>;

export const ImageRequest = z.object({
  provider: ImageProvider,
  model: ImageModel,
  numberOfImages: z.number().min(1).max(4),
  prompt: z.string(),
  aspectRatio: ImageAspectRatio,
});
export type ImageRequest = z.infer<typeof ImageRequest>;

export const DefaultImageRequest: Record<ImageProvider, ImageRequest> = {
  flux: {
    provider: 'flux',
    model: 'flux-pro',
    numberOfImages: 1,
    prompt: '',
    aspectRatio: '1:1',
  },
};
