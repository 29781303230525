import { CopyAll } from "@mui/icons-material";
import { Textarea, IconButton } from "@mui/joy";
import { Stack, Box } from "@mui/system";
import { trpc } from "../../lib/api/trpc/trpc";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useCopySafe } from "../../lib/hooks/useCopySafe";
import { TranslationError } from "./TranslationError";
import TranslateTable from "./TranslateTable";
import { type SelectedLanguages } from "../../pages/[organizationId]/tools/translateContent";
import type {
  DeepLSourceLanguageWithDetect,
  DeepLTargetLanguage,
} from "../../../../backend/src/api/tools/translateContent/deeplTranslator/deeplTranslatorLanguages";

export function TextPanel({
  glossaryId,
  selectedLanguages,
  setSelectedLanguages,
}: {
  glossaryId?: string;
  selectedLanguages: SelectedLanguages;
  setSelectedLanguages: (languages: SelectedLanguages) => void;
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [initRequest, setInitRequest] = useState<boolean>(true);
  const [sourceText, setSourceText] = useState("");
  const [targetText, setTargetText] = useState("");
  const sourceTextRef = useRef<string>(""); // used to access the current source text in the debounced translation function
  const { sourceLanguage, targetLanguage } = selectedLanguages;
  const translationMutation =
    trpc.tools.translateContent.textTranslator.translate.useMutation({
      trpc: {
        context: {
          silentError: true,
        },
      },
    });

  const copy = useCopySafe();

  const loadingTimout = useRef<NodeJS.Timeout>(setTimeout(() => {}, 0));

  const debouncedTranslate = useDebouncedCallback((source) => {
    // If the response takes too long, after 500ms show the loading spinner
    clearTimeout(loadingTimout.current);
    loadingTimout.current = setTimeout(() => {
      setLoading(true);
    }, 500);

    void translationMutation
      .mutateAsync({
        text: source,
        source_lang: sourceLanguage as DeepLSourceLanguageWithDetect,
        target_lang: targetLanguage as DeepLTargetLanguage,
        track: initRequest,
        glossaryId,
      })
      .then((res) => {
        sourceTextRef.current && setTargetText(res.text);
      })
      .finally(() => {
        clearTimeout(loadingTimout.current);
        setLoading(false);
      });
    setInitRequest(false);
  }, 500);

  const translate = async (source: string) => {
    sourceTextRef.current = source;
    setSourceText(source);
    !source && setTargetText("");
    debouncedTranslate(source);
  };

  useEffect(() => {
    void translate(sourceText);
  }, [sourceLanguage, targetLanguage]);

  const copyText = () => {
    copy(targetText);
  };

  return (
    <TranslateTable
      targetText={targetText}
      setSourceText={setSourceText}
      setSelectedLanguages={setSelectedLanguages}
      isLoading={!!sourceText && loading}
      selectedLanguages={selectedLanguages}
    >
      <Stack direction="row" width="100%">
        <Textarea
          sx={{
            p: 2,
            pr: sourceLanguage === "ar" ? 2 : 0,
            pl: sourceLanguage === "ar" ? 0 : 2,
            border: 0,
            borderRadius: "0 0 0 10px",
            borderRight: "1px solid #CED4D9",
            width: "50%",
            height: "50vh",
            maxHeight: "600px",
            direction: sourceLanguage === "ar" ? "rtl" : "ltr",
            overflow: "hidden",
            textarea: {
              overflowY: "auto !important",
            },
          }}
          value={sourceText}
          placeholder={t("enterText")}
          onChange={(e) => {
            void translate(e.target.value);
          }}
        />
        <Box
          sx={{
            p: 2,
            border: 0,
            borderRadius: "0 0 10px 0",
            width: "50%",
            height: "50vh",
            maxHeight: "600px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            overflowY: "auto",
            direction:
              targetLanguage === "ar" && !translationMutation.isError
                ? "rtl"
                : "ltr",
            fontWeight: translationMutation.isError ? "600" : "inherit",
            fontSize: translationMutation.isError ? "18px" : "inherit",
          }}
        >
          {translationMutation.isError ? (
            <TranslationError
              message={t(
                "deeplErrorCodes." +
                  (translationMutation.error?.data?.code ??
                    "INTERNAL_SERVER_ERROR")
              )}
            />
          ) : (
            <div
              // Ensure that line breaks are preserved when copy pasting to word
              // Safe, because the target text is the translated input text from the user themselves
              dangerouslySetInnerHTML={{
                __html: targetText?.replace(/\n/g, "<br />"),
              }}
            />
          )}
          <IconButton
            onClick={copyText}
            sx={{ position: "absolute", bottom: 20, right: 35 }}
            aria-label="Copy translation"
          >
            <CopyAll />
          </IconButton>
        </Box>
      </Stack>
    </TranslateTable>
  );
}
