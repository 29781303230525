import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  FormControl,
  FormLabel,
  Card,
  IconButton,
  Option,
  Typography,
  Select,
} from "@mui/joy";
import { useState } from "react";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { ImageStyleSelector } from "../../../components/image/ImageStyleCard";
import { imageStyles, type ImageStyle } from "../../../components/image/styles";

import { DelayedLoader } from "../../../components/util/DelayadLoader";
import { trpc } from "../../../lib/api/trpc/trpc";
import { useTranslation } from "../../../lib/i18n";

import ToolPage from "../../../components/sidebar/ToolPage.tsx";

import bflIcon from "../../../assets/modelicons/bfl.png";
import { Info, UnfoldMore } from "@mui/icons-material";
import { ImagePromptInputEditor } from "../../../components/image/ImagePromptInputEditor.tsx";
import { GeneratedImages } from "../../../components/image/GeneratedImage.tsx";
import {
  type ImageModel,
  type ImageRequest,
  DefaultImageRequest,
  type ImageProvider,
  type ImageAspectRatio,
  IMAGE_PROVIDERS_MODELS,
} from "../../../../../backend/src/api/tools/images/imagesTypes.ts";
import { MarkdownRenderer } from "../../../components/chat/MarkdownRenderer.tsx";

export { ErrorDisplay as Catch } from "../../../components/util/ErrorDisplay";

const modelIcons: Record<ImageProvider, string> = {
  flux: bflIcon,
};

export default function ImageFactory() {
  const { t } = useTranslation();

  const [imageInput, setImageInput] = useState<ImageRequest>(
    DefaultImageRequest["flux"]
  );

  const [lastAspectRatio, setLastAspectRatio] =
    useState<ImageAspectRatio | null>(null);

  const [modalContent, setModalContent] = useState<
    "styleSelector" | "guide" | null
  >(null);

  const { data: availableProviders } =
    trpc.tools.images.listConfigured.useQuery();

  const availableModels = availableProviders?.flatMap(
    (provider) => IMAGE_PROVIDERS_MODELS[provider]
  );

  const [selectedStyle, setSelectedStyle] = useState<ImageStyle>(
    imageStyles.find(
      (style) => style.nameKey === "images.styles.realistic.label"
    ) || imageStyles[0]
  );

  const {
    mutateAsync: generateImage,
    isPending: generationPending,
    data: generatedImages,
    error: generationError,
  } = trpc.tools.images.generateImages.useMutation({
    trpc: {
      context: {
        silentError: true,
      },
    },
  });

  const onGenerate = () => {
    const promptTemplate = selectedStyle.promptSuffix.includes("{{prompt}}")
      ? selectedStyle.promptSuffix
      : `{{prompt}}${selectedStyle.promptSuffix}`;

    const modelInput = {
      ...imageInput,
      prompt: promptTemplate.replace("{{prompt}}", imageInput.prompt),
    };
    setLastAspectRatio(imageInput.aspectRatio);
    void generateImage(modelInput).catch((e) => {
      console.error(e);
      setLastAspectRatio(null);
    });
  };

  const changeModel = (model: ImageModel) => {
    if (imageInput.model === model) return;
    setImageInput((prev) => ({
      ...prev,
      model,
    }));
  };

  if (!availableModels) return <DelayedLoader />;

  return (
    <ToolPage
      title={t("generateImage")}
      subtitle={t("tools.imageFactory.welcome")}
    >
      <Modal open={!!modalContent} onClose={() => setModalContent(null)}>
        <ModalDialog className="w-fit overflow-y-auto">
          <ModalClose />
          {modalContent === "styleSelector" && (
            <ImageStyleSelector
              selectedStyle={selectedStyle}
              setSelectedStyle={setSelectedStyle}
              onClose={() => setModalContent(null)}
            />
          )}
          {modalContent === "guide" && (
            <div className="p-5">
              <Typography level="h2" pb={2}>
                {t("images.tutorial.title")}
              </Typography>
              <MarkdownRenderer content={t("images.tutorial.body")} />
            </div>
          )}
        </ModalDialog>
      </Modal>
      <div className="flex w-full flex-wrap items-stretch gap-5">
        <Card
          className="flex min-w-[450px] flex-1 flex-col gap-5 "
          sx={{ pb: 4 }}
        >
          <div className="flex items-start justify-between">
            <div className="flex gap-8">
              <FormControl>
                <FormLabel>{t("model")}</FormLabel>
                <Select
                  value={imageInput.model}
                  className="w-[190px]"
                  startDecorator={
                    <img
                      src={modelIcons[imageInput.provider]}
                      className="h-6"
                    />
                  }
                  renderValue={(option) => t("images.model." + option?.value)}
                  onChange={(_, newValue) => {
                    changeModel(
                      newValue ?? DefaultImageRequest[imageInput.provider].model
                    );
                  }}
                >
                  {availableModels.map((model) => (
                    <Option key={model} value={model}>
                      {t(`images.model.${model}`)}
                    </Option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>{t("images.genStyle")}</FormLabel>
                <Button
                  disabled={generationPending}
                  variant="outlined"
                  color="neutral"
                  sx={{
                    backgroundColor: "white",
                    justifyContent: "flex-start",
                    width: "fit-content",
                  }}
                  startDecorator={
                    <ColorLensIcon
                      viewBox="2 2 20 20"
                      sx={{ height: "25px", width: "25px" }}
                    />
                  }
                  endDecorator={<UnfoldMore />}
                  onClick={() => setModalContent("styleSelector")}
                >
                  {t(selectedStyle.nameKey)}
                </Button>
              </FormControl>
            </div>
            <IconButton onClick={() => setModalContent("guide")}>
              <Info />
            </IconButton>
          </div>
          <ImagePromptInputEditor
            input={imageInput}
            setInput={setImageInput}
            onGenerate={onGenerate}
            generating={generationPending}
          />
        </Card>
        <GeneratedImages
          numberOfImages={
            generatedImages?.urls?.length ?? imageInput.numberOfImages
          }
          aspectRatio={lastAspectRatio ?? imageInput.aspectRatio}
          urls={generatedImages?.urls}
          generating={generationPending}
          error={generationError?.message}
        />
      </div>
    </ToolPage>
  );
}
